<template>
 <div style="width: 100%;overflow-x: hidden;">
	 <Navigation></Navigation>
	 <div style="background-color: #F9F8F8;">
	 	  <router-view />
	 </div>

	 <BottomNavigation></BottomNavigation>
	 <el-backtop :right="50" :bottom="100" />
 </div>
</template>

<script setup>
window.Image_url = 'https://ziyuan.rongzw.com/'
import Navigation from '@/components/Navigation.vue'
import BottomNavigation from '@/components/BottomNavigation.vue'
console.log(process.env.NODE_ENV)
</script>





<style lang="scss">
@import "../src/global/css.css";

//追悼模式
//html {
//  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
//  -webkit-filter: grayscale(100%);
//}

body,
html,
#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.subject-width{
	max-width: 1400px;margin: 0 auto;
}
/* 全局滚动条样式优化 */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  background-color: #e4e4e4;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 6px;
}

.treeScrollbar::-webkit-scrollbar-track-piece {
  background-color: #f1f1f1;
}

.treeScrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.treeScrollbar::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 8px;
}

.treeScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}
</style>
