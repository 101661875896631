<template>
  <div v-if="showPage" style="background: white">


    <el-carousel class="hidden-xs-only" height="1000px" style="background: white" indicator-position="none"
                 :autoplay="false">
      <el-carousel-item v-for="(item,index) in list" :key="index">

        <div class="item active">
          <img style="width: 100%" :src="getAssetsImages(item)" alt="">
        </div>


        <div class="carousel-text text-c">
          <div class="carousel-title hidden-xs-only">联众合心 创意展放</div>
<!--          <div class="carSubtitle hidden-xs-only">ZHEJIANG "PHOENIX PLAN" CONTRACT ENTERPRISE</div>-->
          <div class="visible-xs xs-carousel-title">联众合心 创意展放</div>
<!--          <div class="visible-xs xs-carousel-subtitle">ZHEJIANG "PHOENIX PLAN" CONTRACT ENTERPRISE</div>-->
          <p class="carText-con">

            <br/>互联网+科技/创业/智能化/家装平台行业产业聚合
          </p>
        </div>

      </el-carousel-item>
    </el-carousel>


    <el-carousel class="visible-xs" height="300px" style="background: white" indicator-position="none"
                 :autoplay="false">
      <el-carousel-item v-for="(item,index) in list" :key="index">

        <div class="item active">
          <img style="width:100%"  :src="getAssetsImages(item)" alt="">
        </div>


        <div class="carousel-text text-c">
          <div class="carousel-title hidden-xs-only">联众合心 创意展放</div>
<!--          <div class="carSubtitle hidden-xs-only">ZHEJIANG "PHOENIX PLAN" CONTRACT ENTERPRISE</div>-->
          <div class="visible-xs xs-carousel-title">联众合心 创意展放</div>
<!--          <div class="visible-xs xs-carousel-subtitle">ZHEJIANG "PHOENIX PLAN" CONTRACT ENTERPRISE</div>-->
          <p class="carText-con">

            <br/>互联网+科技/创业/智能化/家装平台行业产业聚合
          </p>
        </div>

      </el-carousel-item>
    </el-carousel>

    <div class="bg-f7" style="margin-top: 40px;margin-bottom: 100px;background: white">
      <div class="area-title" style="margin-bottom: 10px;">
        <h2>关于联合创展</h2>
        <p class="carSubtitle " style="margin-top: 10px">联众合心 创意展放</p>
      </div>
      <div class="about-text">
        <div style="font-size: 26px">
          浙江联合创展科技股份有限公司，成立于2010年3月，注册资金988万元，2014年2月完成股改。是由国内资深策划师、设计师、平面设计师和专业技术人员组成，致力于装饰装修工程、机电智能化、住宅集成设计及施工为一体的专业性公司。

        </div>



      </div>
      <img src="../../public/img/homeImg.png" alt="" style="width:100%;height: auto;margin-top: 110px">
    </div>
    <div class="bg-f7" style="height: 540px;text-align: center;margin-bottom: 100px;">
      <div class="area-title" style="padding-top: 90px">
        <h2>业务领域</h2>
        <p class="carSubtitle area-subtitle" style="margin-top: 10px">专注于科技/创业/智能化/装饰领域</p>
      </div>

      <div class="visible-xs xs-business-content">
        <div class="xs-business-area">
          <div class="xs-item-container">
            <div class="xs-item">
              <img src="@/assets/Home/images/home/icon/area1.png" alt="">
              <div class="m-title">荣装网</div>
              <div class="flex-between">
                <div class="s-title">全国领先的互联网家装平台，专注于为大众提供互联网装修解决方案</div>
                <el-button class="more-button" type="primary" round>了解更多</el-button>
              </div>

            </div>
            <div class="xs-item">

              <img src="@/assets/Home/images/home/icon/area2.png" alt="">
              <div class="m-title">易空间</div>
              <div class="flex-between">
                <div class="s-title">设计领域垂直电商，汇集全国优秀设计师作品，设计驱动生态</div>
                <el-button class="more-button" type="primary" round>了解更多</el-button>
              </div>


            </div>
            <div class="xs-item">

              <img src="@/assets/Home/images/home/icon/area3.png" alt="">
              <div class="m-title">精装工程</div>
              <div class="flex-between">
                <div class="s-title">一站式精装，节省业主的时间与精力，拎包入住</div>
                <el-button class="more-button" type="primary" round>了解更多</el-button>
              </div>
            </div>
            <div class="xs-item" style="border-right: 0px">

              <img src="@/assets/Home/images/home/icon/area4.png" alt="">
              <div class="m-title">智慧家居</div>
              <div class="flex-between">
                <div class="s-title">成熟完善等智慧家居体系，多品牌多方案可选，科技改变生活</div>
                <el-button class="more-button" type="primary" round>了解更多</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden-xs-only _content">
        <div class=" businessHome" style="padding-top: 15px;padding-bottom: 15px">
          <div class="business-area">
            <div class="item-container">
              <div class="item">
                <div class="item-content">
                  <img src="@/assets/Home/images/home/icon/area1.png" alt="">
                  <p class="m-title">荣装网</p>
                  <p class="s-title">全国领先的互联网家装平台，专注于为大众提供互联网装修解决方案</p>
                  <el-button class="more-button" type="primary" round>了解更多</el-button>
                </div>
              </div>
              <div class="item">
                <div class="item-content">
                  <img src="@/assets/Home/images/home/icon/area2.png" alt="">
                  <p class="m-title">易空间</p>
                  <p class="s-title">设计领域垂直电商，汇集全国优秀设计师作品，设计驱动生态</p>
                  <el-button class="more-button" type="primary" round>了解更多</el-button>
                </div>
              </div>
              <div class="item">
                <div class="item-content">
                  <img src="@/assets/Home/images/home/icon/area3.png" alt="">
                  <p class="m-title">精装工程</p>
                  <p class="s-title">一站式精装，节省业主的时间与精力，拎包入住</p>
                  <el-button class="more-button" type="primary" round>了解更多</el-button>
                </div>
              </div>
              <div class="item" style="border-right: 0px">
                <div class="item-content">
                  <img src="@/assets/Home/images/home/icon/area4.png" alt="">
                  <p class="m-title">智慧家居</p>
                  <p class="s-title">成熟完善等智慧家居体系，多品牌多方案可选，科技改变生活</p>
                  <el-button class="more-button" type="primary" round>了解更多</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end 业务领域-->
    <!--start 公司视频-->

    <!--end 公司视频-->
    <!--start 关于我们
    <div class="homeAbout">
        <div class="homeAboutCon">
            <h3>联合创展----荣装网家装o2o平台</h3>
            <p>浙江联合创展科技股份有限公司 ，成立于2010年3月。2015年12月联合创展进行企业可持续发展方向调整，由传统家装企业转型至互联网企业，互联网聚合是在互联网+基础上建立的整合行业资源，解决装修行业“不透明、价格贵”的一项重要创新，荣装网是浙江联合创展科技股份有限公司旗下泛家装生态聚合平台品牌。荣装网致力于用互联网思维及技术，着重服务意识，第三方监理、智能在线管理等服务,正在建立一套完善的全国客户监理中心。
                <br /> 传统装饰行业面临变革.家装市场容量过万亿。
            </p>
            <a href="/index.php/index/about.html" class="more">了解更多</a>
        </div>
    </div>
    关于我们-->

    <div class="_content project">
      <h2 class="hidden-xs">集团项目</h2>
      <h2 class="visible-xs" style="width: 100vw;">集团项目</h2>

      <div class="project-content row  ">
        <div class="item col-sm-4 col-xs-12">
          <div class="img-container">
            <img src="@/assets/Home/images/home/project1.png" alt="">
          </div>

          <div class="project-name ">
            共同富裕
          </div>
        </div>
        <div class="item col-sm-4 col-xs-12">
          <div class="img-container">
            <img src="@/assets/Home/images/home/project3.jpeg" alt="">
          </div>
          <div class="project-name  ">
            荣装网服务中心
          </div>
        </div>
        <div class="item col-sm-4 col-xs-12">
          <div class="img-container ">
            <img src="@/assets/Home/images/home/project2.jpeg" alt="">
          </div>
          <div class="project-name  ">
            精装修业务
          </div>
        </div>
      </div>

    </div>
    <!--start 新闻资讯-->
    <div class="_content homeNews">
      <div class="hidden-xs">
        <h2 class="">新闻资讯</h2>
        <p class="carSubtitle m-b-xxl  ">每时每刻了解最新动态</p>
      </div>
      <div class="visible-xs" style="width: 100vw;">
        <h2 class="">新闻资讯</h2>
        <p class="carSubtitle m-b-xxl  ">每时每刻了解最新动态</p>
      </div>

      <div class="_content newsCon" id="gsdt">

        <div class="row">
          <div class="col-sm-4 col-xs-12" v-for="(item,index) in newsList">
            <router-link :to="{path:'news/newsDetail',query:{id:item.id}}" target="_blank">

              <img :src="item.img" alt="">
              <div class="programmeCon">
                <div class="title">{{ item.title }}</div>
                <div class="date">{{ item.showTime }}</div>
                <div class="more">
                  <i class="fa fa-long-arrow-right"></i>更多 / MORE
                </div>
              </div>
            </router-link>
          </div>


        </div>

      </div>

    </div>
    <!--end 新闻资讯-->
    <!--start 友情链接-->
    <!--    <div class="bg-f7">-->
    <!--      <div class="_content padder-v link">-->
    <!--        友情链接：-->
    <!--        <a href="https://www.rongzw.com" class="m-xxs" target="_blank">荣装网</a>-->
    <!--        <a href="http://www.yihonge.com" class="m-xxs" target="_blank">一红信息技术</a>-->
    <!--        <a href="http://www.ehonge.com/" class="m-xxs" target="_blank">易空间</a>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--end 友情链接-->


    <!--    <el-pagination-->

    <!--        :pager-count="count"-->
    <!--        layout="prev, pager, next"-->

    <!--        @current-change="getNewsList"-->
    <!--    />-->
  </div>
</template>

<script setup>
import apiService from '@/api/index';
import {ref, onMounted, reactive} from "vue";
import '@/assets/Home/vendor/jquery/jquery-1.11.3.min.js'
const showPage=ref(false)
const newsList = ref([])
const list = ref([
  '1.jpg',
  '2.jpg',
  '3.jpg'
])

const baseUrl = process.env.BASE_URL


setTimeout(()=>{
  showPage.value=true
},50)
getNewsList(13, 1)
//type=1是公司动态，2是行业动态
//category_id 13=公司动态，14=装饰信息，15=家装案例，16=设计规划，19=科技网络
function getNewsList(category_id, type) {
  apiService.getNewsList({category_id, page_size: type == 1 ? 3 : 6}).then(res => {
    res.data.list = res.data.list.map(item => {
      item.showTime = item.showTime.split(' ')[0]
      return item
    })
    if (type == 1) {
      newsList.value = res.data.list
    }
  })
}

const getAssetsImages = (name) => {
  return `${baseUrl}img/banner/${name}`;


}


</script>


<style scoped lang="scss">
@import '@/assets/Home/css/font-awesome.min.css';
@import '@/assets/Home/css/fonts/otherfont/iconfont.css';
@import '@/assets/Home/vendor/bootstrap/bootstrap.min.css';
@import '@/assets/Home/css/app.css';

.flex-between {
  display: flex;
  justify-content: space-between;
}

.xs-business-content {
  position: relative;
  width: 100vw;
  height: auto;

  .xs-business-area {

    width: 100vw;
    background-color: transparent;
    background-image: url(@/assets/bAreaBg2.jpg);
    background-repeat: no-repeat;
    background: -moz-linear-gradient(top, none, none);
    background: -o-linear-gradient(top, none, none);
    background: linear-gradient(top, none, none);
    background: -webkit-gradient(linear, left top, left bottom, from(none), to(none));
    background-size: cover;


    z-index: 1;

    .xs-item-container {
      position: relative;
      z-index: 1;

      width: 100vw;
      //height: 100%;

      overflow: hidden;

      .xs-item {

        padding: 0 20px 18px 20px;
        color: white;
        //display: inline-block;
        text-align: left;
        width: 100%;
        height: 25%;
        border-bottom: 1px solid #8f8f8f;
        //line-height: 10rem;
        font-family: Source Han Sans;

        .more-button {
          margin-top: 5px;
        }

        img {
          width: 49px;
          height: 49px;
          margin-top: 15px;


        }

        .m-title {
          margin-top: 5px;
          font-size: 30px;

        }

        .s-title {
          width: 50%;
          font-size: 14px;

        }


      }
    }

  }
}

.xs-carousel-title {
  position: relative;
  top: 30px;
  font-size: 2.5rem;
  font-weight: 300;
  opacity: 0.9;

}

.xs-carousel-subtitle {
  position: relative;
  top: 30px;
  font-family: Microsoft YaHei;
  letter-spacing: 1.2px;
  font-size: 1.1rem;
  font-weight: 300;
  opacity: 0.7;
}

@media screen and (min-width: 992px) {
  .about-text {
    width: 1000px;
    background: white;
  }
}

@media screen and (max-width: 768px) {
  .carousel-text {
    top: 60px !important;
  }

  .about-text {
    padding-left: 10px;
    padding-right: 10px;
    width: 98vw;
  }
  .project {
    margin-top: 42vh;
    position: relative;

    .project-content {
      .item {
        position: relative;
        margin-bottom: 10px;

        .img-container {
          width: 94vw !important;
          margin-left: 3vw;
        }

        .project-name {
          width: 100vw !important;

        }
      }
    }
  }
  .carText-con {
    font-size: 1.5rem !important;
  }
  .area-title {
    margin-top: 70px !important;
  }
  .area-subtitle {

    font-size: 2rem !important;
  }

}


.about-text {
  font-size: 16px;
  margin: 0 auto;

  line-height: 28px;
  text-indent: 32px;

}

.project {
  padding-top: 180px;

  .project-content {
    width: 100%;
    min-width: 1200px;
    margin-top: 30px;


    .item {
      .img-container {
        width: 390px;
        overflow: hidden;

        img {
          width: auto;
          height: 290px;

        }
      }

      //background: red;
      //width: 435px;
      height: 330px;
      overflow: hidden;

      .project-name {
        margin-top: 10px;

        text-align: center;
        font-size: 22px;
        width: 390px;
        max-width: 390px;

        font-family: "Source Han Sans";
      }
    }
  }
}

.area-title h2:after {
  content: "";
  margin: 10px auto;
  display: block;
  width: 100px;
  height: 3px;
  background: #dcdcdc;
}

.area-title {

  h2 {
    font-size: 46px !important;
  }

  .carSubtitle {
    font-size: 28px;
  }

  text-align: center;
  position: relative;
  top: -20px;
  margin-bottom: 50px;
}


.item-container {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 1080px;
  height: 100%;
  display: flex;
  overflow: hidden;

  .item {
    cursor: pointer;
    padding: 0 20px 0 20px;
    color: white;
    //display: inline-block;
    text-align: left;
    width: 25%;
    height: 100%;
    border-right: 1px solid #8f8f8f;
    line-height: 1.75;
    font-family: Source Han Sans;

    .item-content:hover {
      transform: translateY(-10px);
      transition: transform 0.3s;
    }

    .item-content {
      margin-top: 120px;
      transition: transform 0.3s;

      .more-button {
        margin-top: 50px;
      }

      img {
        width: 59px;
        height: 59px;
        margin-bottom: 10px;


      }

      .m-title {
        font-size: 30px;

      }

      .s-title {

        font-size: 14px;

      }
    }

  }
}


</style>
