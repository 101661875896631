import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import cooperation from '../views/cooperation.vue'
import Joinus from '../views/Joinus.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: () => import(/* webpackChunkName: "about" */ '../views/cooperation.vue'),
  },
  {
    path: '/Joinus',
    name: 'Joinus',
    component: () => import(/* webpackChunkName: "about" */ '../views/Joinus.vue'),
  },

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs/AboutView.vue'),
  },
  {
    path: '/about/teamHonor',
    name: 'teamHonor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs/teamHonor.vue'),
  },
  {
    path: '/about/developmentHistory',
    name: 'developmentHistory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs/developmentHistory.vue'),
  },
  {
    path: '/about/plan',
    name: 'plan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs/plan.vue'),
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/news/news.vue')
  },
  {
    path: '/news/newsList',
    name: 'newsList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/news/newsList.vue'),

  },
  {
    path: '/news/newsDetail',
    name: 'newsDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/news/newsDetail.vue')
  },
  {
    path: '/business',
    name: 'business',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/business')
  },
  {
    path: '/investor',
    name: 'investor',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/investor')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
